import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// Components
import { Box, Button, Container, Flex, Heading, Box as Section, Text } from "@chakra-ui/react";
import { ProfileDesign } from "../components/ProfileDesign";
import { SEO } from "../components/SEO";
import Img from "gatsby-image";
import { WavySectionTransition } from "../components/WavySectionTransition";
// Icons
import { BsBriefcase } from "react-icons/bs";

const AboutPage = ({ data }) => (
  <>
    <SEO title="About" />

    <Section mt="50px">
      <Container maxWidth="container.lg" p={6} paddingTop={[8, null, null, 20]} paddingBottom={8}>
        <Flex display={["block", null, "flex"]} sx={{ gap: "1rem" }}>
          <Box flex="1">
            <ProfileDesign />
          </Box>
          <Box flex="2">
            <Heading mt={4} color="brand.main" textTransform="uppercase" fontWeight="bold" fontSize={["3xl", "4xl"]}>
              Know bout Me
            </Heading>
            <Text mt={4}>
              I like a lot of stuff like snowboarding, dogs, traveling, coding, bran muffins, pancakes, coffee, 90s hip hop, cheesy music,
              EDM, tech, smoking meats, gadgets, photography, video editing, chinese food, mexican food, pho, steak, smoothies, ice-cream,
              Lao Tsu, kung fu movies, football, classic 80s movies, fantasy football, food trucks, Wu-Tang, sous vide, and various things.
            </Text>
            <Text>I currently live in Corvallis, Oregon.</Text>
            <blockquote>
              He who knows, does not speak. He who speaks, does not know.
              <br />- Lao Tsu
            </blockquote>
          </Box>
        </Flex>
      </Container>
    </Section>

    <WavySectionTransition
      background="var(--color-white)"
      color="var(--color-primary-dark)"
      d="M0.00,49.98 C233.63,347.86 224.60,-167.27 503.38,77.45 L500.00,150.00 L0.00,150.00 Z"
    />

    <Section background="var(--color-primary-dark)" py={8} position="relative" overflowX="clip">
      <Container maxWidth="container.lg" p={6}>
        <Heading fontSize={["3xl", "4xl"]} color="brand.lightShades" textTransform="uppercase">
          Professional
        </Heading>
        <Heading fontSize={["xl", "2xl"]} color="brand.lightShades" textTransform="uppercase">
          Third Person Bio
        </Heading>
        <Text mt={4} color="brand.lightShades">
          Noah is a marketing technologist with over a decade of experience in the medical device industry who specializes in articulating
          the value of digital technologies and engagement strategies to company leaders. He has a deep understanding of data and analytics
          coupled with the creative mindset to transition raw data into actionable insights and compelling marketing campaigns.
        </Text>
        <Text mt={4} color="brand.lightShades">
          He is well-versed in patient education through the development of multiple awareness campaigns that include content marketing,
          social media, search engine marketing, SEO, lead nurturing campaigns and marketing automation (Marketo Certified Expert).
        </Text>
        <Text mt={4} color="brand.lightShades">
          Noah currently works at Amazon Web Services (AWS).
        </Text>
      </Container>
      <BackgroundImage />
    </Section>

    <WavySectionTransition
      background="var(--color-primary-dark)"
      color="var(--color-gray-200)"
      d="M0.00,49.98 C233.63,347.86 224.60,-167.27 503.38,77.45 L500.00,150.00 L0.00,150.00 Z"
    />

    <Section backgroundColor="brand.lightShades" py={12}>
      <Container maxWidth="container.lg" p={6}>
        <Heading fontSize={["lg", null, null, "2xl"]} textAlign="center" color="#a0a0a0" fontWeight="medium">
          The places I've worked at:
        </Heading>
        <Box mx="auto" mt={4} maxWidth={["250px", "container.sm"]} display={["block", "grid"]} gridTemplateColumns="1fr 1fr" gridGap={12}>
          <Img fluid={data.aws.childImageSharp.fluid} />
          <Img fluid={data.biotronik.childImageSharp.fluid} />
          <Img fluid={data.merit.childImageSharp.fluid} />
          <Img fluid={data.stryker.childImageSharp.fluid} />
        </Box>
      </Container>
    </Section>

    <Section background="white" py={20}>
      <Box textAlign="center">
        <Link to="/work">
          <Button colorScheme="facebook" variant="outline" textTransform="uppercase">
            Go To Work
          </Button>
        </Link>
      </Box>
    </Section>
  </>
);

// Styled Components
const BackgroundImage = styled(BsBriefcase)`
  position: absolute;
  color: var(--color-white);
  width: 100px;
  height: 100px;
  top: -2%;
  right: 0%;
  opacity: 0.1;
  transform: rotate(0.95turn);
  display: block;

  @media ${({ theme }) => theme.mq.md} {
    width: 250px;
    height: 250px;
    top: -17%;
    right: 7%;
  }
`;

export const pageQuery = graphql`
  query {
    stryker: file(relativePath: { eq: "stryker-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    biotronik: file(relativePath: { eq: "biotronik-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    merit: file(relativePath: { eq: "merit-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aws: file(relativePath: { eq: "aws-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default AboutPage;
