import React from "react";
import { motion } from "framer-motion";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
// Components
import Img from "gatsby-image";
import noah from "../../images/noah.svg";
import wong from "../../images/wong.svg";

const ProfileDesign = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          profile: file(relativePath: { eq: "profile_design.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <Circle initial={{ opacity: 0, scale: 1.2 }} animate={{ opacity: 0.7, scale: 1 }} transition={{ duration: 0.8, delay: 0.4 }} />
          <Noah variants={variants} img={noah} initial="hidden" animate="visible" transition={{ duration: 0.8, delay: 0.4 }} />
          <Wong img={wong} variants={variants} initial="hidden" animate="visible" transition={{ duration: 0.8, delay: 0.6 }} />
          <Parent
            initial={{
              opacity: 0,
              y: "20%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 0.8,
              type: "spring",
              stiffness: 80,
            }}
          >
            <FakeBgImage fluid={data.profile.childImageSharp.fluid} />
          </Parent>
        </Container>
      )}
    />
  );
};

// Styled Components
const Container = styled.div`
  position: relative;
  width: 300px;
  height: 530px;
  margin: 0 auto;
`;
const Noah = styled(motion.div)<ProfileImageProps>`
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  left: -2%;
  top: 4.1%;
  height: 90px;
  width: 230px;
  position: absolute;
`;
const Wong = styled(motion.div)<ProfileImageProps>`
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  left: -1%;
  top: 18%;
  height: 100px;
  width: 300px;
  position: absolute;
`;
const Circle = styled(motion.div)`
  width: 280px;
  height: 280px;
  left: 0.3%;
  top: 43.7%;
  border-radius: 500px;
  background: var(--color-primary);
  position: absolute;
`;
const Parent = styled(motion.div)`
  filter: drop-shadow(2px 2px 4px #000000);
  position: absolute;
  width: 250px;
  height: 400px;
  left: 7.9%;
  top: 30.7%;
`;
const FakeBgImage = styled(Img)`
  border-radius: 0 0 250px 250px;
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
  }
`;

// Types
type ProfileImageProps = {
  img: any;
};

// Framer Motion Variants
const variants = {
  hidden: { scale: 1, opacity: 0, x: "-40%" },
  visible: { scale: 1, opacity: 1, x: 0 },
};

export { ProfileDesign };
