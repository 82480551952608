import React from "react";
import styled from "styled-components";
// Components

const WavySectionTransition = ({
  background = "var(--color-wavy-transition-background)",
  color = "var(--color-primary-dark)",
  d = "M0.00,49.98 C149.99,150.00 250.56,2.47 503.38,77.45 L500.00,150.00 L0.00,150.00 Z",
  height,
}: {
  background?: string;
  color?: string;
  d?: string;
  height?: number;
}) => {
  // Generate d from https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections
  return (
    <WavyTransitionContainer style={{ background: background }} height={height}>
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%", display: "block" }}>
        <path d={`${d}`} style={{ stroke: "none", fill: color }} />
      </svg>
    </WavyTransitionContainer>
  );
};
const WavyTransitionContainer = styled.div<WavySectionTransitionProps>`
  height: ${({ height }) => (height ? `${height}px` : "40px")};
  overflow: hidden;
  background: ${({ background }) => (background ? background : "var(--color-primary-dark)")};

  @media ${({ theme }) => theme.mq.sm} {
    height: ${({ height }) => (height ? `${height * 1.5 + 20}px` : "60px")};
  }
  @media ${({ theme }) => theme.mq.md} {
    height: ${({ height }) => (height ? `${height * 2 + 40}px` : "80px")};
  }
  @media ${({ theme }) => theme.mq.lg} {
    height: ${({ height }) => (height ? `${height * 2.5 + 60}px` : "100px")};
  }
  @media ${({ theme }) => theme.mq.xl} {
    height: ${({ height }) => (height ? `${height * 3 + 80}px` : "120px")};
  }
`;

// Styled Components

// Types
type WavySectionTransitionProps = {
  background?: string;
  height?: number;
};

export { WavySectionTransition };
